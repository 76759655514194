// import type, { AppProps } from 'next/app';
import { SWRConfig } from 'swr';
import fetchJson from "lib/fetchJson";
import '../styles/globals.css';
import Layout from '../components/Layout';

function USOM( { Component, pageProps } ) {
  return (
    <SWRConfig
      value={ {
        fetcher: fetchJson,
        onError: ( err ) => {
          console.error( 'ERR:', err );
        }
      } }
    >
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </SWRConfig>
  );
}

export default USOM;
